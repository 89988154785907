import { Controller } from "stimulus";

import { submitForm } from "init/ajax";
import { loadModalUrl } from "../modal/utils"

export default class extends Controller {
  async submit(event) {
    event.preventDefault();

    const form = event.target;

    if(window._learnq) {
      _learnq.push(['identify', {
        '$email' : form.querySelector('.email-field').value}]);
      _learnq.push(['track', 'kosher-brochure-subscribed', {}]);
    }

    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      const response = await submitForm(form);
      if (response.error) {
        alert(response.error);
        return false;
      }

      const container = document.querySelector('.contact-sign-up');
      container.innerHTML = '<h2 class="title">Thank you! Please check your email.</h2>';

      if(response.redirect && response.redirect.length){
        setTimeout(() => {
          window.location = response.redirect
        }, 1500);
      }

      return false;
    }
  }
}
