import { Controller } from "stimulus";
import Glide from "@glidejs/glide";
/* Magic Number for Slider's Autoplay Delay */
const AUTOPLAY_DELAY = 3500;

export default class extends Controller {
  connect = () => {
    this.sliders = document.querySelectorAll(".experiences-carousel .glide");
    window.addEventListener("load", this.mount.bind(this));
  };

  mount = () => {
    this.sliders.forEach(slider => {
      slider = this.initSlider(slider);
      slider.on("run", num => {
        let sel = ".glide__slide:not(.glide__slide--clone)";
        let slides = this.element.querySelectorAll(sel);
        let index = slider.index;
        let el = slides[index];
        let cur = this.element.querySelector(".glide__slide--active");

        // on the first slide going forward we also need the clone next to last slide
        if (slider.index == 0 && num.direction == ">") {
          let clone = cur.nextElementSibling;
          clone.classList.add("glide__slide--active");
        }

        // on the last slide going backward we also need the clone previous
        if (slider.index == slides.length - 1 && num.direction == "<") {
          let clone = cur.previousElementSibling;
          clone.classList.add("glide__slide--active");
        }

        cur.classList.remove("glide__slide--active");
        el.classList.add("glide__slide--active");
      });
    });
  };

  initSlider = slider => {
    // Configure and init slider
    const glide = new Glide(slider, {
      autoplay: AUTOPLAY_DELAY,
      focusAt: "center",
      /* GlideJS is desktop-first */
      gap: 52,
      peek: 205,
      perView: 3,
      startAt: 0,
      type: "carousel",
      breakpoints: {
        1599: {
          perView: 3,
          peek: 140,
          gap: 48
        },
        1199: {
          perView: 1,
          peek: 300,
          gap: 42
        },
        919: {
          perView: 1,
          peek: 200,
          gap: 36
        },
        699: {
          perView: 1,
          peek: 64,
          gap: 24
        }
      }
    }).mount();

    /* START: Click-to-Nav on Carousel Items. */
    // Grab the relevant DOM elements.
    const slides = [
      ...glide.selector.querySelectorAll(
        ".glide__slide:not(.glide__slide--clone)"
      )
    ];
    // The clones wrap, so we have to take the split array and manipulate it a bit.
    // Grab the relevant DOM elements.
    const allClonesSelector = `.glide__slide--clone`;
    const afterClonesSelector = `.glide__slide:not(.glide__slide--clone) ~ .glide__slide--clone`;
    // Query for them, and store as an Array instead of the returned NodeList.
    const allClones = [...glide.selector.querySelectorAll(allClonesSelector)];
    const afterClones = [
      ...glide.selector.querySelectorAll(afterClonesSelector)
    ];
    // Grab the 'before' clones by filtering the 'after' ones from all.
    const beforeClones = allClones.filter(c => afterClones.indexOf(c) == -1);
    // Assign the click event listeners.
    // Non-clones just navigate to themselves.
    slides.map((slide, idx) =>
      slide.addEventListener("click", () => glide.go(`=${idx}`))
    );
    // Clones have to be manually navigated to the non-clone item they represent.
    // TODO: Find a way to make the go(`={i}`) function loop instead of rewind/fast-forward.
    beforeClones.map((slide, idx) =>
      slide.addEventListener("click", () => {
        idx == beforeClones.length - 1
          ? /* Move back 1. */ glide.go(`<`)
          : idx == beforeClones.length - 2
          ? /* 2nd from the last. */ glide.go(`=${slides.length - 2}`)
          : null;
      })
    );
    afterClones.map((slide, idx) =>
      slide.addEventListener("click", () => {
        idx == 0
          ? /* Move forward 1.*/ glide.go(`>`)
          : idx == 1
          ? /* 2nd element. */ glide.go(`=1`)
          : null;
      })
    );
    /* END: Click-to-Nav on Carousel Items. */

    return glide;
  };
}
