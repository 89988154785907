import { Controller } from "stimulus";
import Glide from "@glidejs/glide";

export default class extends Controller {
  connect() {
    this.sliders = document.querySelectorAll(".info-slider .glide");
    window.addEventListener("load", this.mount.bind(this));
  }

  mount() {
    this.sliders.forEach(slider =>
      slider.querySelectorAll(".glide__slide").length > 1
        ? new Glide(slider).mount()
        : false
    );
  }
}
