import {Controller} from "stimulus";

export default class extends Controller {
  static targets = [ "adults", 'select', 'label']

  showNumberOfGuests() {
    const guestsNumber = parseInt(this.adultsTarget.value, 10);
    if (guestsNumber > 0) {
      this.labelTarget.innerText = `Guests: ${guestsNumber}`
    } else {
        this.labelTarget.innerText = `Select Guests`
    }
  }

  updateURLWithGuests() {
    const queryData = Site.serializedQueryParams();
    // checkin/checkout are added back throught pushState
    // so i just remove guests here so it doesnt get added twice.
    delete(queryData.guests);
    delete(queryData.checkin);
    delete(queryData.checkout);
    const guestsNumber = parseInt(this.adultsTarget.value, 10);
    if(guestsNumber > 0) {
      queryData.guests = guestsNumber;
    }

    let path = `${window.location.origin}/stay`;
    if (location.pathname.includes('/special-offers/')){
      path = `${window.location.origin}${location.pathname}`;
    }

    const newQuery = Site.objectToQuery(queryData);
    if(newQuery == ""){
      window.location = `${path}`;
    } else{
      window.location = `${path}?${newQuery}`;
    }
  }


  applyGuests() {
    this.selectTarget.classList.toggle('open');
    this.updateURLWithGuests()
  }

  toggleOptions() {
    this.selectTarget.classList.toggle('open');
  }

  increment(e) {
    e.stopPropagation();
    e.preventDefault();
    const {category} = e.currentTarget.dataset;
    const targetName = `${category}Target`;
    const currentValue = parseInt(this[targetName].value, 10);
    if (!isNaN(currentValue)) {
      this[targetName].value = currentValue + 1;
    }
    this.showNumberOfGuests();
  }

  decrement(e) {
    e.stopPropagation();
    e.preventDefault();
    const {category} = e.currentTarget.dataset;
    const targetName = `${category}Target`;
    const currentValue = parseInt(this[targetName].value, 10);
    if (!isNaN(currentValue) && currentValue > 0) {
      this[targetName].value = currentValue - 1;
    } else {
      this[targetName].value = 0;
    }
    this.showNumberOfGuests();
  }
}
