import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.autoplay();
  }

  autoplay() {
    if (this.element.dataset.autoplay != "true") return;
    let options = {
      threshold: 0.2
    };

    if (this.inobservable()) {
      this.element.play();
    } else {
      let observer = new IntersectionObserver(this.animate.bind(this), options);
      observer.observe(this.element);
    }
  }

  inobservable() {
    return (
      !("IntersectionObserver" in window) ||
      !("IntersectionObserverEntry" in window) ||
      !("intersectionRatio" in window.IntersectionObserverEntry.prototype)
    );
  }

  animate(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.play();
        if (!entry.target.loop) observer.unobserve(entry.target);
      } else {
        // left the viewport
        entry.target.pause();
      }
    });
  }
}
