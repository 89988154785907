import Flatpickr from "stimulus-flatpickr";
import { deleteCookie } from "init/cookie";

export default class extends Flatpickr {
  static targets = ["arrive", "depart", "availability", "availabilityDefaults"];

  initialize() {
    this.availabilityData = JSON.parse(this.availabilityTarget.innerHTML);
    this.availabilityDefaults = JSON.parse(
      this.availabilityDefaultsTarget.innerHTML
    );
    if (this.availabilityData === undefined) return;
    this.config = {
      mode: "range",
      minDate: "today",
      clickOpens: false,
      defaultDate: this.defaultDates().map(date => {
        // can't use the Flatpickr helper since it isn't instantiated yet...
        // bear in mind that JS months are 0-indexed, whilst days are 1-indexed
        // ¯\_(ツ)_/¯
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
      }),
      disable: this.getDisabledDates()
    };
  }

  defaultDates() {
    const arrive = new Date();
    const depart = new Date();
    const offset = parseInt(document.querySelector("#date_offset").value || 10, 10);
    const range = parseInt(document.querySelector("#range_offset").value || 10, 10);
    const arriveDate = arrive.getDate() + offset;
    const departDate = arriveDate + range;
    arrive.setDate(arriveDate);
    depart.setDate(departDate);
    return [arrive, depart];
  }

  connect() {
    this.arriveInput = this.element.querySelector("#arrive");
    this.arriveMoInput = this.element.querySelector(".arrive .small-date");
    this.departInput = this.element.querySelector("#depart");
    this.departMoInput = this.element.querySelector(".depart .small-date");
    this.arriveDateInput = this.element.querySelector("#arrive_date");
    this.departDateInput = this.element.querySelector("#depart_date");
    this.arriveInput.parentNode.addEventListener(
      "click",
      this.togglePicker.bind(this)
    );
    this.departInput.parentNode.addEventListener(
      "click",
      this.togglePicker.bind(this)
    );
    super.connect();
    // this needs to be called after super.connect() so that this.fp is defined
    this.setDates(this.defaultDates());
    // remove `animate` class from calendar since it was causing it to jump
    // when applying `transform` css to adjust the position to the design
    document.querySelector(".flatpickr-calendar").classList.remove("animate");
    this.clearCalendarClass();
    // Add the "Clear Dates" link to the flatpicker calendar.
    this.addClearDatesButton();
  }

  addClearDatesButton() {
    const calendarEl = document.querySelector(".flatpickr-calendar");
    // If the flatpickr is on the page, and doesn't have a clear dates button.
    if (
      this.fp.element &&
      calendarEl &&
      !calendarEl.querySelector(".clear-dates")
    ) {
      const clearDatesButton = document.createElement("button");
      clearDatesButton.classList.add("clear-dates");
      clearDatesButton.textContent = "Clear Dates";
      calendarEl.appendChild(clearDatesButton);
      clearDatesButton.addEventListener("click", () => {
        deleteCookie("filter_by_dates");
        this.fp.close();
        this.clearCalendarClass();
        calendarEl
          .querySelectorAll(".flatpickr-day")
          .forEach(el =>
            el.classList.remove("selected", "startRange", "inRange", "endRange")
          );
        this.setDates(this.defaultDates());
      });
    }
  }

  togglePicker(e) {
    // Grab the class lists we're comparing to determine if the target is open.
    const calendarType = document.querySelector(".flatpickr-calendar")
      .classList;
    const pickerType = e.currentTarget.parentNode.classList;

    // Close the flatpickr.
    this.fp.close();

    // Compare the calendar class to the target.
    if (calendarType.contains(pickerType)) {
      // If calendar and target are the same, it was open and we can clear it.
      this.clearCalendarClass();
    } else {
      // Otherwise, we should add the target class to the calendar and open it.
      this.updateCalendarClass(pickerType);
      this.fp.open(e, e.target);
    }
  }

  setDates(dates) {
    const lookup = ["arrive", "depart"];
    dates.forEach((date, index) => {
      const dayInput = this[`${lookup[index]}Input`];
      const monthInput = this[`${lookup[index]}MoInput`];
      const dateInput = this[`${lookup[index]}DateInput`];
      dayInput.innerText = this.fp.formatDate(date, "j");
      monthInput.innerText = this.fp.formatDate(date, "M Y");
      dateInput.value = this.fp.formatDate(date, "Y-m-d");
    });
    this.fp.setDate(dates);
  }

  clearCalendarClass() {
    const calendar = document.querySelector(".flatpickr-calendar");
    calendar.classList.remove("arrive");
    calendar.classList.remove("depart");
  }

  updateCalendarClass(type) {
    this.clearCalendarClass();
    const calendar = document.querySelector(".flatpickr-calendar");
    calendar.classList.add(type);
  }

  change(selectedDates, dateStr, instance) {
    if (selectedDates[0] === undefined) return
    const lookup = ["arrive", "depart"];
    const index = selectedDates.length % 2;
    const type = lookup[index];
    let dayInput = this[`${type}Input`];
    const startDate = instance.formatDate(selectedDates[0], 'F j, Y');
    const startEl = document.querySelector(`[aria-label="${startDate}"]`);
    let disable;

    // do not allow departure days to be selected as arrival date
    if (
      selectedDates.length >= 1 &&
      !!startEl.classList.value.match(/flatpickr-departure|flatpickr-sold-out/)
    ) {
      disable = true;
    }

    // do not allow departure days to be within date selection
    if (!disable && selectedDates.length === 2) {
      const range = this.getDaysArray(selectedDates[0], selectedDates[1])
      range.shift()
      range.pop()
      disable = range.find(d => {
        const date = instance.formatDate(d, 'F j, Y')
        const el = document.querySelector(`[aria-label="${date}"]`)
        return !!el.classList.value.match(/flatpickr-departure|flatpickr-sold-out/)
      })
    }

    if (disable) {
      this.clearInstanceDates(instance)
      this.fp.open();
      dayInput = this.departInput
    } else {
      this.updateCalendarClass(type);
      this.setDates(selectedDates);
    }
    /* eslint-disable-next-line no-underscore-dangle */
    instance._positionCalendar(dayInput);
  }

  getDaysArray(start, end) {
    for (var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)) {
      arr.push(new Date(dt))
    }
    return arr;
  }

  clearInstanceDates(instance) {
    const firstVisibleDay = document.querySelector('.flatpickr-day:not(.hidden):not(.prevMonthDay)')
    const date = new Date(firstVisibleDay.getAttribute('aria-label'))
    instance.clear()
    instance.jumpToDate(date)
  }

  dayCreate(dObj, dStr, fp, dayElem) {
    const humanDate = dayElem.getAttribute("aria-label");
    const formatted = fp.formatDate(new Date(humanDate), "Y-m-d");
    const day = this.availabilityData[formatted];
    if (typeof day === "undefined") return;
    if (day.depart === 1) {
      // since departure days are technically disabled this is a hack to allow us to select them
      dayElem.classList.add('flatpickr-departure');
      dayElem.classList.remove('flatpickr-disabled');
      let selected = fp.selectedDates[1]
      if (selected) {
        selected = fp.formatDate(new Date(selected), 'Y-m-d')
        if (selected === formatted) {
          dayElem.classList.add('selected')
          dayElem.classList.add('endRange')
        }
      }
    } else if (day.avail === 0) {
      dayElem.classList.add('flatpickr-sold-out')
    }
  }

  getDisabledDates() {
    const availability = this.availabilityData;
    if (availability === undefined) return;
    // let date = document.getElementById("depart_date").value;
    const disabled = Object.keys(availability).filter(
      key => availability[key].avail === 0
    );
    // eslint-disable-next-line consistent-return
    return disabled;
  }

  submit(e) {
    e.preventDefault();
    const arrive = this.arriveTarget.value;
    const depart = this.departTarget.value;
    const numGuestsTarget = document.querySelector("#num_guests");
    const numGuests = numGuestsTarget ? numGuestsTarget.value : 0;
    const destinations = document.querySelector(".datepicker-hero #destinations");

    if (arrive === "" || depart === "") return;
    let queryData = {};
    if(destinations) {
      queryData = Object.assign(queryData, { destinations: destinations.value })
    }

    queryData = Object.assign(queryData, { checkin: arrive, checkout: depart });
    if (numGuests > 0) {
      queryData = Object.assign(queryData, { min_guests: numGuests });
    }

    /* eslint-disable-next-line no-undef */
    const query = Site.objectToQuery(queryData);

    window.location = `/stay/?${query}`;
  }
}
