import Flatpickr from "stimulus-flatpickr";
import { setCookie, deleteCookie, getCookie } from "init/cookie";

const moreThanOneDateError = "You must select at least more than one date";
let noBreakDownABTest = false;

export default class extends Flatpickr {
  static targets = [
    "availability",
    "availabilityDefaults",
    "rightArrow",
    "leftArrow",
    "timestamp"
  ];

  initialize() {
    this.availabilityData = JSON.parse(this.availabilityTarget.innerHTML);
    this.availabilityDefaults = JSON.parse(
      this.availabilityDefaultsTarget.innerHTML
    );
    if (this.availabilityData === undefined) return;
    this.config = {
      inline: true,
      mode: "range",
      minDate: "today",
      clickOpens: false,
      nextArrow: document.querySelector(".month-arrow.next-month").innerHTML,
      prevArrow: document.querySelector(".month-arrow.previous-month")
        .innerHTML,
      showMonths: 2,
      disable: this.getDisabledDates()
    };

    // AB test
    noBreakDownABTest = getCookie("ABTest_nobreakdown").length > 0;
  }

  connect() {
    if (this.availabilityData === undefined) return;
    this.datesApplied = false;
    this.arriveTarget = document.getElementById("arrive");
    this.departTarget = document.getElementById("depart");
    this.promoCodeTarget = document.getElementById("coupon");
    this.numGuestsTarget = document.getElementById("num_guests");
    this.unitIdTarget = document.getElementById("unit_id");
    this.houseTypeEl   = document.getElementById("house_type");
    this.datepickers = document.querySelectorAll(
      '[data-controller="calendar-hero"]'
    );

    /* Fire super.connect() before using `this.fp` so it's available */
    super.connect();
    /* Month Arrow Controls */
    this.rightArrowTarget.addEventListener("click", () =>
      this.fp.changeMonth(1)
    );
    this.leftArrowTarget.addEventListener("click", () =>
      this.fp.changeMonth(-1)
    );
    /* Default selected dates */
    const defaultDates = [this.arriveTarget.value, this.departTarget.value];
    if (this.arriveTarget.value && this.departTarget.value) {
      this.fp.setDate(defaultDates);
      this.fp.jumpToDate(this.arriveTarget.value);
    }

    window.addEventListener("load", this.initDates.bind(this));
    this.initClearDatesButton();
    this.initPLPactions();
    this.initPDPactions();
    this.setTotals();
  }

  initClearDatesButton() {
    const calendarEl = this.element.parentNode;
    const clearButton = calendarEl.querySelector(".clear-dates button");
    clearButton.addEventListener("click", () => {
      this.clearDates();
    });
  }

  initPLPactions() {
    if (!document.querySelector(".plp-bar")) return;
    const dateSelector = document.querySelector(".select-dates");
    dateSelector.addEventListener("click", this.submitPLP.bind(this));
  }

  submitPLP(e) {
    e.preventDefault();
    /* eslint-disable no-undef */
    let queryData = Site.serializedQueryParams();
    delete queryData.page; // prevent going to wrong page of results.

    const arrive = this.arriveTarget.value;
    const depart = this.departTarget.value;

    if (arrive === "" || depart === "") return;

    if (arrive === depart) {
      this.setErrorMessages(moreThanOneDateError);
      return;
    }

    queryData = Object.assign(queryData, { checkin: arrive, checkout: depart });

    /* eslint-disable no-undef */
    const query = Site.objectToQuery(queryData);
    // remove pagination when submitting calendar, so you aren't stuck on an
    // orphaned page with no results
    const path = window.location.pathname.split("page/")[0];
    window.location = `${path}?${query}`;
  }

  initDates() {
    if (this.arriveTarget.value && this.departTarget.value) {
      /* eslint-disable no-underscore-dangle */
      const fp = this.element._flatpickr;
      const arriveDate = fp.parseDate(this.arriveTarget.value, "Y-m-d");
      const departDate = fp.parseDate(this.departTarget.value, "Y-m-d");
      const selectedDates = [arriveDate, departDate];
      this.datesApplied = true;
      fp.setDate(selectedDates, true);
      if (!this.booking_breakdown) return;
      this.submit();
    }
  }

  totalsAlreadySet() {
    return this.item_container.innerHTML.replace(/^\s+/, "") !== "";
  }

  initPDPactions() {
    this.booking_breakdown = document.querySelector("#booking-breakdown");
    if (!this.booking_breakdown) return;
    this.pdp_bar = document.querySelector(".pdp-booking-bar");
    this.mobile_pdp_bar = document.querySelector(".mobile-pdp-booking-bar");
    this.item_container = this.booking_breakdown.querySelector(
      ".booking-items"
    );
    this.dropdown_trigger = document.querySelector(".title");
    this.book_now_btn = document.querySelector(".pdp-booking-bar .book-now");

    const dateSelector = this.element.parentNode.querySelector(".select-dates");
    dateSelector.addEventListener("click", this.submit.bind(this));

    // since this event is bound to one specific element in the DOM and this
    // class can be invoked multipled times in a page, we check if the click
    // event is already bound and only add the listener if is not already bound
    if (!this.dropdown_trigger.dataset.bound) {
      this.dropdown_trigger.addEventListener("click", e => {
        e.stopPropagation();
        if (this.item_container.innerHTML.replace(/^\s+/, "") === "") return;
        this.pdp_bar.classList.toggle("open");
      });
      this.book_now_btn.addEventListener(
        "click",
        this.bookOrOpenCalendar.bind(this)
      );

      this.dropdown_trigger.dataset.bound = true;
    }

    document.addEventListener("click", () =>
      this.pdp_bar.classList.remove("open")
    );
    this.booking_breakdown.addEventListener("click", e => e.stopPropagation());
  }
  bookOrOpenCalendar(e) {
    e.stopPropagation();
    if (this.totalsAlreadySet()) {
      Site.initiateCheckout();
    } else {
      document.getElementById("calendar-overlay").classList.add("open");
    }
  }
  // eslint-disable-next-line no-unused-vars
  change(selectedDates, dateStr, instance) {
    if (selectedDates[0] === undefined) return;
    const bookingBar = document.querySelector(".booking-bar");
    const checkin = bookingBar.querySelector(".check-in-selector .inner-text");
    const checkout = bookingBar.querySelector(
      ".check-out-selector .inner-text"
    );
    const prettyFmt = [checkin, checkout];
    const rawFmt = [this.arriveTarget, this.departTarget];
    const startDate = instance.formatDate(selectedDates[0], "F j, Y");
    const startEl = document.querySelector(`[aria-label="${startDate}"]`);
    let disable = false;
    let blockingDays = [];
    // disable min Days
    if (this.isPDP() && selectedDates.length == 1) {
      const formatTokens = "Y-m-d"; // 2023-12-29
      const firstDayAvailability = this.availabilityData[
        instance.formatDate(selectedDates[0], formatTokens)
      ];
      const firstDayIndex = Object.keys(this.availabilityData).findIndex(
        day => day == firstDayAvailability.date
      );
      blockingDays = Object.keys(this.availabilityData).slice(
        firstDayIndex + 1,
        firstDayIndex + firstDayAvailability.min + 1 // doing + 1 here so we have the first date available in those.
      );

      const firstDayAvailable = blockingDays.slice(-1);
      const firstDayAvailableEls = document.querySelectorAll(`[aria-label="${instance.formatDate(new Date(firstDayAvailable[0].replaceAll('-','/')), "F j, Y")}"]`);

      const shouldJump = (els) => {
        // source: https://github.com/jquery/jquery/blob/main/src/css/hiddenVisibleSelectors.js
        const isVisible = (elem) => {
          return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );
        }

        // this logic is necessary because the calendar sometimes add next month days
        // in the current month but in an hidden form.
        if(els.length == 0){
          return true;
        }
        let hiddenElements = 0;
        const elements = Array.from(els);
        if(elements[0].classList.contains('hidden') && elements.length == 1) {
          return true;
        }

        if(elements.length == 1 && !isVisible(els[0])){
          return true;
        }

        if(elements.length == 2 && elements[0].classList.contains('hidden') && (!elements[1].classList.contains('hidden') && !isVisible(elements[1]) ) ){
          return true;
        }

        return false;
      }

      if(shouldJump(firstDayAvailableEls)){
        instance.jumpToDate(new Date(firstDayAvailable), false);
      }
    }

    // do not allow departure days to be selected as arrival date
    if (
      selectedDates.length >= 1 &&
      startEl &&
      !!startEl.classList.value.match(/flatpickr-departure|flatpickr-sold-out/)
    ) {
      disable = true;
    }
    // do not allow departure days to be within date selection
    if (!disable && selectedDates.length === 2) {
      const range = this.getDaysArray(selectedDates[0], selectedDates[1]);
      range.shift();
      range.pop();
      disable = range.find(d => {
        const date = instance.formatDate(d, "F j, Y");
        const el = document.querySelector(`[aria-label="${date}"]`);
        if (el === null) {
          return false; // the date is in the previous month hence not showing in calendar.
        }
        return !!el.classList.value.match(
          /flatpickr-departure|flatpickr-sold-out/
        );
      });
    }
    if (disable) {
      const queryData = Site.serializedQueryParams();
      if (queryData.checkin && queryData.checkout) {
        const fp = this.element._flatpickr;
        selectedDates[0] = fp.parseDate(queryData.checkin, "Y-m-d");
        selectedDates[1] = fp.parseDate(queryData.checkout, "Y-m-d");
      }

      selectedDates.forEach((date, index) => {
        prettyFmt[index].innerText = this.fp.formatDate(date, "m/d/Y");
        rawFmt[index].value = this.fp.formatDate(date, "Y-m-d");
      });
    } else {
      selectedDates.forEach((date, index) => {
        prettyFmt[index].innerText = this.fp.formatDate(date, "m/d/Y");
        rawFmt[index].value = this.fp.formatDate(date, "Y-m-d");
      });
      // update other datepickers to have the same dates
      this.datepickers.forEach(datepicker => {
        if (this.element === datepicker) return;
        datepicker._flatpickr.setDate(selectedDates);
      });
      // Show the dropdown in the PDP Booking Bar
      const ddCtn = document.querySelector(
        ".pdp-booking-bar .range .dropdown-container"
      );
      if (ddCtn) ddCtn.style.display = "block";
      if (selectedDates.length > 1) {
        this.activateSelectDatesBtn();
        // this.submit(); // hotfix so people doenst need to click on search at PDP page to book.
        // after select departure we can unblock dates.
        blockingDays.forEach( (el) => { el.classList.remove('flatpickr-disabled--temp', 'flatpickr-disabled') });
      } else {
        this.deactivateSelectDatesBtn();
      }

      if (
        this.element.classList.contains("autoclose") &&
        selectedDates.length === 2
      ) {
        document.body.style.overflowY = "auto";
        document.getElementById("calendar-overlay").classList.remove("open");
        const plpQuery = {
          checkin: this.fp.formatDate(selectedDates[0], "Y-m-d"),
          checkout: this.fp.formatDate(selectedDates[1], "Y-m-d")
        };
        setCookie("filter_by_dates", JSON.stringify(plpQuery), 3);
      }
    }
  }

  getDaysArray(start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  clearInstanceDates(instance) {
    const firstVisibleDay = document.querySelector(
      ".flatpickr-day:not(.hidden)"
    );
    const date = new Date(firstVisibleDay.getAttribute("aria-label"));
    instance.clear();
    instance.jumpToDate(date);
  }

  dayCreate(dObj, dStr, fp, dayElem) {
    if(Object.keys(this.availabilityData).length == 0){
      return 0;
    }
    let today = new Date();
    today = fp.formatDate(today, "Y-m-d");
    const humanDate = dayElem.getAttribute("aria-label");
    const formatted = fp.formatDate(new Date(humanDate), "Y-m-d");
    const day = this.availabilityData[formatted];
    let text = "";

    const minDisabled = this.getMinBookingWindowDates();
    let blockingDays = [];

    if(dObj[0] && !dObj[1] && this.isPDP()) {
      const formatTokens = "Y-m-d"; // 2023-12-29
      const firstDayAvailability = this.availabilityData[
        fp.formatDate(dObj[0], formatTokens)
      ];
      const firstDayIndex = Object.keys(this.availabilityData).findIndex(
        day => day == firstDayAvailability.date
      );
      blockingDays = Object.keys(this.availabilityData).slice(
        firstDayIndex + 1,
        firstDayIndex + firstDayAvailability.min 
      );
    }

    if (!day && ![].includes.call(dayElem.classList, "flatpickr-disabled")) {
      dayElem.classList.add("flatpickr-disabled");
    }

    /* Typecast `minDisabled` to a String for ie11 which lacks Object.includes() */
    if (`${minDisabled}`.includes(formatted)) {
      dayElem.classList.add("flatpickr-disabled");
    }

    

    if (today === formatted) {
      // eslint-disable-next-line no-param-reassign
      dayElem.innerHTML += `<span class="rate title today">TODAY</span>`;
    }

    if (typeof day !== "undefined") {
      if (day.depart === 1) {
        // since departure days are technically disabled this is a hack to allow us to select them
        if (day.depart === 1 && day.avail === 1) {
          // since a check-in and a check-out can occur on the same day, we need to add a new class to differentiate it
          dayElem.classList.add("flatpickr-back-to-back");
        } else {
          dayElem.classList.add("flatpickr-departure");
        }
        if(!dayElem.classList.contains("flatpickr-disabled--temp")){
          dayElem.classList.remove("flatpickr-disabled");
        }
        let selected = fp.selectedDates[1];
        if (selected) {
          selected = fp.formatDate(new Date(selected), "Y-m-d");
          if (selected === formatted) {
            dayElem.classList.add("selected");
            dayElem.classList.add("endRange");
          }
        }
      } else if (day.avail === 0) {
        dayElem.classList.add("flatpickr-sold-out");
      } else {
        const { min, max } = day.rate;
        // sometimes Track mixes up max and min, so we select lowest
        const rate = Math.min(min, max);
        if (!document.querySelector(".plp-bar, .plp-bar-home")) {
          text = `$${Math.round(rate)}`;
        }
        if (dayElem.classList.contains("endRange")) text = "DEPART";
      }
      // eslint-disable-next-line no-param-reassign
      dayElem.innerHTML += `<span class="rate">${text}</span>`;
    }

    if (blockingDays.includes(formatted)) {
      dayElem.classList.add("flatpickr-disabled", 'flatpickr-disabled--temp');
      dayElem.classList.remove("flatpickr-back-to-back");
    }
  }

  getDisabledDates() {
    const availability = this.availabilityData;
    const today = new Date().toISOString().slice(0, 10);
    if (availability === undefined) return;
    // don't disabled selected departure date
    const depart = document.getElementById("depart");
    const date = depart ? depart.value : null;
    const disabled = Object.keys(availability).filter(
      key =>
        (availability[key].avail === 0 || availability[key].depart === 1) &&
        date !== key
    );

    // disable todays date at PLP pages
    // and if its on PDP and time is after 12pm.
    try {
      if (!this.isPDP()) {
        disabled.push(today);
      } else {
        const timeStampDate = new Date(
          parseInt(this.timestampTarget.innerHTML.replace(/\D/g, "")) * 1000
        );
        if (timeStampDate.getUTCHours() - 4 >= 12) {
          disabled.push(today);
          console.info("disabling today date");
        }
      }
    } catch (e) {
      console.log(e);
      // just to prevent site crash if some lost component  using it.
    }

    // eslint-disable-next-line consistent-return
    return disabled;
  }

  getMinBookingWindowDates() {
    const minWindow = this.availabilityDefaults.minBookingWindow;
    const range = [...Array(minWindow).keys()];
    return range.map(num => this.getFormattedDate(num));
  }

  getFormattedDate(num) {
    const today = new Date();
    const date = new Date(today);
    if (num > 0) {
      date.setDate(date.getDate() + num);
    }
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yyyy = date.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  }

  getLowestMaximum() {
    let availability = this.availabilityData;
    const queryData = Site.serializedQueryParams();
    if (queryData.checkin && queryData.checkout) {
      const range = this.availabilityRange();
      availability = range.reduce((carry, date) => {
        carry[date] = availability[date];
        return carry;
      }, {});
    }

    const rates = Object.keys(availability).map(key => {
      const { min, max } = availability[key].rate;
      // sometimes Track mixes up max and min, so we select lowest
      const rate = Math.min(min, max);
      return rate;
    });
    return Math.min(...rates);
  }

  setTotals() {
    const lowest = Math.round(this.getLowestMaximum());
    const actualTotals = document.querySelectorAll(".actual-total");
    actualTotals.forEach(actualTotal => {
      // eslint-disable-next-line no-param-reassign
      actualTotal.innerText = `$${lowest}`;
    });
  }

  clearDates() {
    deleteCookie("filter_by_dates");

    const queryData = Site.serializedQueryParams();
    let destination = window.location.pathname;

    delete queryData.checkout;
    delete queryData.checkin;

    const query = Site.objectToQuery(queryData);
    /* eslint-disable-next-line eqeqeq */
    destination = query == "" ? destination : `${destination}?${query}`;
    window.location = destination;
  }

  clearErrorMessages() {
    const errorContainers = document.querySelectorAll(".select-dates .error");
    errorContainers.forEach(errorContainer => {
      // eslint-disable-next-line no-param-reassign
      errorContainer.innerText = "";
    });
  }

  setErrorMessages(message) {
    const event = new Event("calendar-error-triggered", {});
    document.dispatchEvent(event);

    const errorContainers = document.querySelectorAll(".select-dates .error");
    errorContainers.forEach(errorContainer => {
      // eslint-disable-next-line no-param-reassign
      errorContainer.innerText = message;
    });
  }

  availabilityRange() {
    const dateArray = [];
    const startDate = new Date(this.arriveTarget.value.replace(/-/g, "/"));
    const endDate = new Date(this.departTarget.value.replace(/-/g, "/"));
    let currDate = startDate;
    while (currDate <= endDate) {
      const newDate = this.fp.formatDate(new Date(currDate), "Y-m-d");
      dateArray.push(newDate);
      currDate = currDate.addDays(1);
    }
    return dateArray;
  }

  updateQuote(response) {
    this.clearErrorMessages();

    if (response.status === 422 && response.ruleCodeId === 200) {
      // this means that the dates selected are in the past so clear dates
      this.clearDates();
      return;
    }
    if (response.status === 422 || response.status === 500 || !response.isValid) {
      // this means that the dates selected do not meet the minimum requirement
      // response.ruleCodeId == 402
      // Track made a generic update which renders this useless error response
      // "Available rate type was not found for the quote parameters."
      // so we have to do the following logic to find the minimum night stay in
      // the given time range.
      const range = this.availabilityRange();
      const avail = this.availabilityData;
      const minStay = Math.max(...range.map(day => avail[day].stay.min));

      if(response.error) {
        this.setErrorMessages(response.error.message);
        return;
      }

      if (response.detail.match(/(Promo)/i)) {
        this.setErrorMessages(
          `Sorry, coupon code is not valid for this listing. Please try another coupon code.`
        );
      } else {
        this.setErrorMessages(
          `Your date selection requires a ${minStay} night stay minimum. Please extend your stay.`
        );
      }
      return;
    }

    if(response.warnings && response.warnings.length > 0){
      const warning = response.warnings[0];
      if(warning.code === 'RATE_MIN_STAY') {
        const errorMessage = `Your date selection requires a ${warning.params.min} night stay minimum. Please extend your stay.`;
        this.setErrorMessages(errorMessage);
        return;
      }
      this.setErrorMessages(warning.message);
      return;

    }

    // since the response did't return sooner, assume success
    /* eslint-disable-next-line no-undef */
    Site.quoteStored = true;

    // Write these dates to the PLP filter, in case we go back:
    const plpQuery = {
      checkin: this.arriveTarget.value,
      checkout: this.departTarget.value
    };
    setCookie("filter_by_dates", JSON.stringify(plpQuery), 3);

    // close overlay if open
    document.getElementById("calendar-overlay").classList.remove("open");

    const items = response.parsed_items;
    if (items === undefined) return;
    /* Wipe any existing items */
    this.item_container.innerHTML = "";
    /* Populate the list */
    // ABTEST - ORIGINAL
    if (!noBreakDownABTest) {
      items.forEach(item => {
        if (!item.label || item.label === "avg_rate_item") {
          const bookingItem = document.createElement("DIV");
          bookingItem.classList.add("booking-item");
          const desc = document.createElement("DIV");
          desc.classList.add("item-desc");
          desc.innerText = item.name;
          const total = document.createElement("DIV");
          total.classList.add("item-total");
          total.innerText = `${item.value}`;
          bookingItem.appendChild(desc);
          bookingItem.appendChild(total);
          this.item_container.appendChild(bookingItem);
        }
      });
    }
    // ABTEST - Variant
    let ABTotal = 0;
    if (noBreakDownABTest) {
      let counter = 0;
      items.forEach(item => {
        if (!item.label || item.label === "avg_rate_item") {
          if (counter < 1) {
            const bookingItem = document.createElement("DIV");
            bookingItem.classList.add("booking-item");
            const desc = document.createElement("DIV");
            desc.classList.add("item-desc");
            desc.innerText = item.name;
            const total = document.createElement("DIV");
            total.classList.add("item-total");
            total.innerText = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD"
            }).format(item.value.replace("$", ""));
            bookingItem.appendChild(desc);
            bookingItem.appendChild(total);
            this.item_container.appendChild(bookingItem);

            ABTotal = item.value;
          }
          counter++;
        }
      });
    }

    if (!this.datesApplied) {
      this.pdp_bar.classList.add("open");
    }
    this.datesApplied = false;
    const subtotal = document.querySelector(".booking-subtotal .item-total");
    const barTotal = document.querySelector(".actual-total");
    const fromText = document.querySelector(".from");
    const totalText = document.querySelector(".title span.bottom");
    subtotal.innerText = `$${response.total}`;
    barTotal.innerText = `$${response.total}`;
    fromText.style.display = "none";
    totalText.innerText = "Total";
    // replace mobile totals
    this.mobile_pdp_bar.querySelector(
      ".totals"
    ).innerHTML = this.pdp_bar.querySelector(".totals").innerHTML;
    this.mobile_pdp_bar.querySelector(".title").addEventListener("click", e => {
      e.stopPropagation();
      this.mobile_pdp_bar.classList.toggle("open");
    });
    this.mobile_pdp_bar.querySelector(
      ".detailed-totals-bar"
    ).innerHTML = this.booking_breakdown.innerHTML;
    this.mobile_pdp_bar.classList.add("adjusted");
    // 💩 Update the button text, should just call mpb.updateButton() but Stimulus 😢
    this.mobile_pdp_bar.querySelector(
      ".book-now .btn"
    ).textContent = `Book Now`;

    // Variant
    if (noBreakDownABTest) {
      document
        .querySelectorAll(".booking-subtotal")
        .forEach(el => (el.style.display = "none"));
      document
        .querySelectorAll(".booking-items .booking-item")
        .forEach(el => (el.style.padding = 0));
      document
        .querySelectorAll(".totals .bottom")
        .forEach(el => (el.innerText = "Rent"));
      document.querySelectorAll(".actual-total").forEach(
        el =>
          (el.innerText = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
          }).format(ABTotal.replace("$", "")))
      );
    }
  }

  activateSelectDatesBtn() {
    const dateBtns = document.querySelectorAll(".select-dates button");
    dateBtns.forEach(btn => {
      /* eslint-disable-next-line no-param-reassign */
      btn.disabled = false;
    });
    if (this.item_container) this.item_container.innerHTML = "";
  }

  deactivateSelectDatesBtn() {
    const dateBtns = document.querySelectorAll(".select-dates button");
    dateBtns.forEach(btn => {
      /* eslint-disable-next-line no-param-reassign */
      btn.disabled = true;
    });

    if (this.book_now_btn) this.book_now_btn.disabled = true;
  }

  activateBookNow() {
    const bookNow = document.querySelector("#book-now");
    const isInquiry = document.querySelector(".inquiry-overlay");
    if (!bookNow) return;
    const text = isInquiry ? "Inquire" : "Book Now";
    bookNow.innerText = text;
    bookNow.disabled = false;
    if (isInquiry) {
      const arrive = document.querySelector("#arrive").value;
      const depart = document.querySelector("#depart").value;
      document.querySelector("#form_fields_checkin").value = arrive;
      document.querySelector("#form_fields_checkout").value = depart;
    }
  }

  pdpUrl() {
    return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  }

  submit() {
    // some other place passing data to calendar, this method only works and should be called on PDP's.
    if (!this.isPDP()) {
      return;
    }
    const data = {
      //unitId: this.unitIdTarget ? this.unitIdTarget.value : null,
      unitId: this.unitIdTarget.value,
      arrivalDate: this.arriveTarget.value,
      departureDate: this.departTarget.value,
      clearAddOns: true,
      houseType: this.houseTypeEl.value
    };

    if (this.promoCodeTarget && this.promoCodeTarget.value) {
      data.promoCode = this.promoCodeTarget.value;
    }

    const prevUrl = window.location.href;
    let query = {
      checkin: this.arriveTarget.value,
      checkout: this.departTarget.value
    };

    if (this.promoCodeTarget && this.promoCodeTarget.value) {
      query.coupon = this.promoCodeTarget.value;
    }

    if (this.numGuestsTarget && this.numGuestsTarget.value) {
      query.guests = this.numGuestsTarget.value;
    }

    query = Site.objectToQuery(query);
    const url = `${this.pdpUrl()}?${query}`;

    if (this.arriveTarget.value === this.departTarget.value) {
      this.setErrorMessages(moreThanOneDateError);
      return;
    }

    // stop from repeat requests to Track API
    if (Site.quoteStored && prevUrl === url) {
      // close overlay if open
      document.getElementById("calendar-overlay").classList.remove("open");
    } else {
      /* eslint-disable-next-line no-restricted-globals */
      if (history.pushState) {
        window.history.pushState({ path: url }, "", url);
      } else {
        window.location.href = url;
      }
      this.activateBookNow();
      Site.postJson("/quote", data, this.updateQuote.bind(this));
    }
    document.body.style.overflowY = "auto";
  }

  isPDP() {
    return document.body.classList.contains("template-listing-single");
  }
}
