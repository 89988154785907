import { Controller } from "stimulus";
import Glide from "@glidejs/glide";
import { unveilImages, unveilImage } from "init/unveil";

export default class extends Controller {
  static targets = [
    "captionContainer",
    "controls",
    "innerThumbnails",
    "thumbnails"
  ];
  connect() {
    this.sliders = this.element.querySelectorAll(".glide");
    this.captionText = this.element.querySelectorAll(".caption-text");
    this.count = this.element.querySelectorAll(".thumbnail").length;
    this.large_images = this.element.querySelectorAll(
      ".glide__slides [data-src]"
    );
    this.thumb_images = this.element.querySelectorAll(".thumbnails [data-src]");
    window.addEventListener("load", this.mount.bind(this));
  }

  mount() {
    let options = {
      autoplay: 4000,
      type: "carousel"
    };
    let thumbnail = document.querySelector(".thumbnail");
    let style = thumbnail.currentStyle || window.getComputedStyle(thumbnail);
    let margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    this.thumbnailWidth = thumbnail.offsetWidth + margin;
    this.sliders.forEach(slider => this.initSlider(new Glide(slider, options)));
  }

  initSlider(slider) {
    let thumbs = this.element.querySelectorAll(".thumbnail");
    this.setThumbnails(slider);
    Array.from(thumbs).forEach((thumb, index) => {
      thumb.addEventListener("click", () => slider.go(`=${index}`));
    });

    slider.on("run", num => this.setThumbnails(slider));
    slider.on("resize", num => this.setThumbnails(slider));

    slider.on('mount.before', () => {
      this.element.querySelector(".mobile-controls .left-arrow").addEventListener("click", () => {
        slider.go("<");
      });

      this.element.querySelector(".mobile-controls .right-arrow").addEventListener("click", () => {
        slider.go(">");
      });
    })

    slider.mount();
  }

  set(slider) {
    let index = slider.index;
    let slides = slider.selector.querySelectorAll(".glide__slide:not(.glide__slide--clone)");
    let currentSlide = slides[index];
    this.setCaption(currentSlide);
    this.setActiveThumbnail(index);
  }

  positionThumbnails(slider) {
    let containerWidth =
      this.captionContainerTarget.offsetWidth -
      this.controlsTarget.offsetWidth -
      56;
    let thumbCount = Math.floor(containerWidth / this.thumbnailWidth);
    let resizedWidth = thumbCount * this.thumbnailWidth;
    let moveCount = Math.floor(thumbCount / 2);

    this.maxThumbs = thumbCount;

    // position thumbnail container
    let adjustedIndex = slider.index - moveCount;
    // first half set to beginning
    if (slider.index < moveCount) adjustedIndex = 0;
    // last half set to end
    if (slider.index >= this.count - moveCount)
      adjustedIndex = this.count - thumbCount;
    let move = this.thumbnailWidth * adjustedIndex;
    this.thumbnailsTarget.style.width = `${resizedWidth}px`;
    this.innerThumbnailsTarget.style.transform = `translateX(-${move}px)`;
  }

  setThumbnails(slider) {
    this.positionThumbnails(slider);
    this.set(slider);
    this.unveilImages(slider);
  }

  unveilImages = unveilImages;
  unveilImage = unveilImage;

  setActiveThumbnail(index) {
    let thumbs = this.element.querySelectorAll(".thumbnail");
    let curThumb = thumbs[index];
    Array.from(thumbs).forEach(thumb => thumb.classList.remove("active"));
    curThumb.classList.add("active");
  }

  setCaption(slide) {
    let caption = slide.dataset.caption;
    this.captionText.forEach(captionText => (captionText.innerText = caption));
  }
}
