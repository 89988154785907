import { Controller } from "stimulus";
import Glide from "@glidejs/glide";
import { unveilImages, unveilImage } from "init/unveil";

export default class extends Controller {
  static targets = [
    "controls",
    "innerThumbnails",
    "thumbnails"
  ];

  connect() {
    this.sliders = this.element.querySelectorAll(".glide");
    this.large_images = this.element.querySelectorAll(
      ".glide__slides [data-src]"
    );
    window.addEventListener("DOMContentLoaded", this.mount.bind(this));
    window.addEventListener("resize", () => {
      this.setArrowsPosition()
    });
  }

  mount() {
    let options = {
      autoplay: 15000,
      type: "carousel",
      perView: 1
    };
    this.sliders.forEach(slider => this.initSlider(new Glide(slider, options)));
  }

  initSlider(slider) {

    slider.on('mount.before', () => {
      this.element.querySelector(".controls.left").addEventListener("click", () => {
        slider.go("<");
      });

      this.element.querySelector(".controls.right").addEventListener("click", () => {
        slider.go(">");
      });
    })

    slider.mount();

    this.setBulletsPosition();
    this.setArrowsPosition();
  }

  setBulletsPosition() {
    const [firstImage] = this.element.querySelectorAll(
      ".glide__slides .glide__slide--active img"
    );
    const [firstVideo] = this.element.querySelectorAll(
        ".glide__slides .glide__slide--active video"
    );

    let resizeElement = firstImage;
    if (!firstImage) {
      resizeElement = firstVideo;
    }

    new ResizeObserver(() => {
      const imageHeight = resizeElement.clientHeight
      const [bullets] = this.element.querySelectorAll(".glide__bullets")
      if (bullets) {
        bullets.style.top = `${imageHeight - 64}px`;
      }
    }).observe(resizeElement);
  }

  setArrowsPosition() {
    const [firstImage] = this.element.querySelectorAll(
        ".glide__slides .glide__slide--active img"
    );
    const [firstVideo] = this.element.querySelectorAll(
        ".glide__slides .glide__slide--active video"
    );

    let resizeElement = firstImage;
    if (!firstImage) {
      resizeElement = firstVideo;
    }


    if (window.innerWidth < 1200) {
      new ResizeObserver(() => {
        const imageHeight = resizeElement.clientHeight
        const [arrow_left] = this.element.querySelectorAll(".glide__track .controls.left")
        const [arrow_right] = this.element.querySelectorAll(".glide__track .controls.right")
        if (arrow_left && arrow_right) {
          arrow_left.style.top = `${imageHeight - 44}px`;
          arrow_right.style.top = `${imageHeight - 44}px`;
        }
      }).observe(resizeElement);
    } else {
      new ResizeObserver(() => {
        const [arrow_left] = this.element.querySelectorAll(".glide__track .controls.left")
        const [arrow_right] = this.element.querySelectorAll(".glide__track .controls.right")
        if (arrow_left && arrow_right) {
          arrow_left.style.top = '44%';
          arrow_right.style.top = '44%';
        }
      }).observe(resizeElement);
    }
  }


  set(slider) {
    let index = slider.index;
    let slides = slider.selector.querySelectorAll(".glide__slide");
    let currentSlide = slides[index];
  }

  unveilImages = unveilImages;
  unveilImage = unveilImage;
}
