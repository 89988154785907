export function unveilImages(slider) {
  // unveil current, next, and previous large images
  let current = slider.index;
  let start = current - 1;
  let end = current + 1;
  if (start < 0) start = this.count + start;
  if (end >= this.count) end = end - this.count;
  Array.from([current, end, start]).forEach(idx =>
    this.unveilImage(this.large_images[idx])
  );

  // unveil current 11 thumbnails, next 11 thumbnails, and previous 11 thumbnails
  let keys = [...this.thumb_images.keys()];
  let current_array = keys.slice(current, current + this.maxThumbs);
  let start_array = keys.slice(current - this.maxThumbs, current);
  let end_array = keys.slice(
    current + this.maxThumbs,
    current + 2 * this.maxThumbs
  );
  if (current - this.maxThumbs < 0) {
    start_array = keys.slice(current - this.maxThumbs);
    start_array = start_array.concat(keys.slice(0, current));
  }
  current_array.forEach(idx => this.unveilImage(this.thumb_images[idx]));
  start_array.forEach(idx => this.unveilImage(this.thumb_images[idx]));
  end_array.forEach(idx => this.unveilImage(this.thumb_images[idx]));
}

export function unveilImage(image) {
  if (image) {
    const event = new CustomEvent("lazy-load");
    image.dispatchEvent(event);
  }
}
