import { fetchWithCSRF } from "init/ajax";

export function parentModal(childNode) {
  const modals = document.querySelectorAll(".modal");
  for (const modal of modals) {
    if (modal.contains(childNode)) {
      return modal;
    }
  }
}

export async function loadModalUrl(url, { replace = false } = {}) {
  const response = await fetchWithCSRF(url);
  const json = await response.json();

  let html;
  if (json.redirect_to) {
    location.href = json.redirect_to;
    html = "";
  } else {
    html = json.html;
  }

  let fragment = document.createRange().createContextualFragment(html);

  if (replace) {
    parentModal(replace).replaceWith(fragment.querySelector(".modal") || "");
  } else {
    document.body.appendChild(fragment);
  }
}

export function openModal(element) {
  const event = new CustomEvent("modal-open", { bubbles: true });

  element.dispatchEvent(event);
}

export function closeModal(element) {
  const event = new CustomEvent("modal-close", { bubbles: true });

  element.dispatchEvent(event);
}
