import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["destinations", 'arrive', 'depart', 'destinationButton'];

  selections = [];

  connect() {
    this.overlay = document.getElementById("calendar-overlay");
    this.closeBtn = this.overlay.querySelector(".close-calendar");
    this.closeBtn.addEventListener("click", this.close.bind(this));
    this.selections = [...this.destinationsTarget.querySelectorAll('.options ul li.active')].map((o) => o.dataset.value);

    const destinationDropDown = document.querySelector('.nav_container .destinations');

    document.querySelector(".select-dates").addEventListener('click', this.close.bind(this));

    window.addEventListener('scroll', () => {
      if (window.scrollY > 780) {
        this.element.classList.remove('hidden');
      } else {
        this.element.classList.add('hidden');
      }
    });
  }

  close(e) {
    e.preventDefault();
    document.body.style.overflowY = "auto";
    this.overlay.classList.remove("open");
  }

  open() {
    document.body.style.overflow = "hidden";
    this.overlay.classList.add("open");
  }

  toggleDestinationMenu(e) {
    e.preventDefault();
    e.stopPropagation();
    this.destinationsTarget.classList.toggle("open");
  }

  toggleDestination(e) {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.toggle('active');

    const currentSelectionIndex = this.selections.findIndex((i) => i === e.currentTarget.dataset.value);

    if (currentSelectionIndex !== -1) {
      this.selections.splice(currentSelectionIndex, 1);
    } else {
      this.selections.push(e.currentTarget.dataset.value);
    }

    this.updateLabel()
  }

  updateLabel() {
    if (this.selections.length === 0) {
      this.destinationButtonTarget.classList.remove('select-button--active');
      this.labelTarget.textContent = this.currentLabelContent
    } else {
      this.destinationButtonTarget.classList.add('select-button--active');
      this.labelTarget.textContent = `${this.currentLabelContent} (${this.selections.length})`;
    }
  }

  submit() {
    const searchParams = new URLSearchParams();
    if (this.selections.length > 0)
      searchParams.set("destinations", this.selections.join());

    if (this.arriveTarget.value !== '' && this.departTarget.value !== '') {
      searchParams.set("checkin", this.arriveTarget.value)
      searchParams.set("checkout", this.departTarget.value)
    }

    window.location.href = `/stay?${decodeURIComponent(searchParams)}`;
  }
}
