import { Controller } from "stimulus";
import Glide from "@glidejs/glide";
import { useDispatch } from "stimulus-use";
import { getCookie } from "../../init/cookie";
import getFilters from "../../util/filter_params";
import unitQuote from "../../util/quote";


export default class extends Controller {
  static targets = ["glide", "bookBtn", "minRate"];

  connect() {
    useDispatch(this);
    this.setupGlideJS();
    this.setDatesVariables();
    this.updateBookButton();

    if (this.hasMinRateTarget) {
      this.observeVisibility();
    }
  }

  setupGlideJS() {
    const slides = this.glideTarget.querySelector(".glide__slides");
  
    if (slides.children.length === 0) {
      return;
    }
  
    this.glide = new Glide(this.glideTarget, {
      type: "slider",
      perView: 1,
      animationDuration: 300,
      gap: 20,
      swipeThreshold: 5,
      dragThreshold: 5
    }).mount();
  }

  setDatesVariables() {
    // get dates from url parameters
    const urlParams = new URLSearchParams(window.location.search);
    this.checkin = urlParams.get("checkin");
    this.checkout = urlParams.get("checkout");

    if (this.checkin && this.checkout) return;

    // get dates from cookies if url parameters doesn't contain dates
    const cookies = JSON.parse(getCookie("filter_by_dates") || "{}");
    this.checkin = cookies.checkin;
    this.checkout = cookies.checkout;
    
  }

  updateBookButton() {
    if (!this.hasBookBtnTarget) return;
    if (!this.checkin  || !this.checkout) {
      this.bookBtnTarget.classList.add("noDates")
    } else {
      this.bookBtnTarget.classList.remove("noDates");
    }
  }

  goToProperty() {
    const bookUrl = this.bookBtnTarget.getAttribute("data-url");
    if (this.checkin && this.checkout && bookUrl) {
      window.location.href = `${bookUrl}?checkin=${this.checkin}&checkout=${this.checkout}`;
    } else if (bookUrl) {
      window.location.href = `${bookUrl}`;
    }
      
  }

  handleBookNowBtnClick() {
    if (!this.checkin  || !this.checkout) {
      this.dispatch("openCalendar");
    } else {
      this.goToProperty();
    }
  }

  async observeVisibility() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async entry => {
        if (entry.isIntersecting) {
          // Element is visible
          await this.fetchPrice();
          // Optionally, disconnect observer if you only need to fetch once
          observer.disconnect();
        }
      });
    });

    observer.observe(this.element);
  }

  async fetchPrice() {
    const filters = getFilters();

    if (!filters.get('checkin') || !this.data.get('unit-id')) { 
       this.minRateTargets.forEach(target => {
         target.classList.toggle("min-rate-loading");
         target.innerHTML = `from ${this.data.get("min-rate")} / night`
       });
       return;
    }

    const data = {
      unit_id: this.data.get('unit-id'),
      type: this.data.get('unit-type'),
      arrival_date: filters.get('checkin'),
      departure_date: filters.get('checkout')
    };

    const result = await unitQuote(data);

    if (result.status && result.status === 'error') {
      this.minRateTargets.forEach(target => {
        target.classList.toggle("min-rate-loading");
        target.classList.toggle("min-rate-error");
        target.innerHTML = `from ${this.data.get("min-rate")} / night`
      })
    } else {
      const {nightly_rate} = result;
      this.minRateTargets.forEach(target => {
        target.classList.remove("min-rate-loading");
        target.innerHTML = `from $${parseFloat(nightly_rate).toLocaleString("en-US")} / night`
      });
    }
  }
}
