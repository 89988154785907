import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selectedOption', 'dropdown', 'dropdownBtn'];

  handleMouseDown(e) {
    const isDropdown = this.dropdownTarget.contains(e.target);
    const isDropdownBtn = this.dropdownBtnTarget.contains(e.target);
    if (!isDropdown && !isDropdownBtn) {
      this.closeDropdown();
    }
  }

  toggleDropdown() {
    this.dropdownTarget.classList.toggle('active');
  }

  closeDropdown() {
    this.dropdownTarget.classList.remove('active');
  }

  selectOption(event) {
    const selectedValue = event.currentTarget.dataset.value;
    this.selectedOptionTarget.textContent = event.currentTarget.textContent;
    this.toggleDropdown();
    this.updateURLWithSortOption(selectedValue);
  }

  updateURLWithSortOption(selectedValue) {
    const urlObject = new URL(window.location.href);

    if (selectedValue) {
      const extractedValue = JSON.parse(selectedValue)[0];
      urlObject.searchParams.set('sort_by', extractedValue);
    } else {
       urlObject.searchParams.delete('sort_by');
    }

    urlObject.searchParams.delete('page');
    window.location.href = urlObject.toString();
  }
}
