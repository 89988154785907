import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["input", 'select', 'label', 'datalist', 'dataListTemplate', 'selectButton']

    counter = 0

    selections = []

    currentLabelContent = this.labelTarget.textContent

    connect() {

        for (let i = 0; i < this.inputTarget.options.length; i++) {
            const option = this.inputTarget.options.item(i);
            if (option.innerHTML === '') {
                continue
            }

            const template = this.dataListTemplateTarget.content.firstElementChild.cloneNode(true);

            template.dataset.index = i;
            template.dataset.value = option.value;
            template.dataset.label = option.innerHTML;
            template.querySelector('span.option-label').textContent = option.innerHTML;

            this.datalistTarget.append(template);
        }
        this.dataListTemplateTarget.remove();
    }

    toggleOptions() {
        this.selectTarget.classList.toggle('select--open');
    }

    inputChange(e) {
        this.selectOption(e.currentTarget.selectedOptions[0]);
    }

    selectOption(e) {
        e.stopPropagation();
        e.preventDefault();

        e.currentTarget.classList.toggle('option-selected');

        const currentSelectionIndex = this.selections.findIndex((i) => i === e.currentTarget.dataset.value);

        if (currentSelectionIndex !== -1) {
            this.selections.splice(currentSelectionIndex, 1);
        } else {
            this.selections.push(e.currentTarget.dataset.value);
        }

        const {index} = e.currentTarget.dataset;
        this.inputTarget.options[index].selected = e.currentTarget.classList.contains('option-selected');
        this.updateLabel();
    }

    updateLabel() {
        if (this.selections.length === 0) {
            this.labelTarget.textContent = this.currentLabelContent
            this.selectButtonTarget.classList.remove('select-button--active')
        } else {
            this.selectButtonTarget.classList.add('select-button--active')
            this.labelTarget.textContent = `${this.currentLabelContent} (${this.selections.length})`;
        }
    }

    applyChoices(e) {
        e.preventDefault();
        this.selectTarget.classList.add('select--selected');
    }
}
