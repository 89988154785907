import { Controller } from "stimulus";

export default class extends Controller {
  // These are the timestamp texts in the h3 tag
  static targets = ["timestamp"];

  connect = () => {
    // Init some instance variables.
    this.timestampEls = this.timestampTargets;
    // Set background video on window resize.
    window.addEventListener("resize", this.updateBackgroundVideo);
    // Set background video on initial load.
    this.updateBackgroundVideo();
    // Tick once before the timer is set (0s interval).
    this.mobileTick();
    // Init our time interval for mobile.
    this.initTimer();
  };

  // Set <video>'s src attribute for background video.
  updateBackgroundVideo = () => {
    // DOM Element.
    const bgEl = this.element.querySelector(".bg-video");

    // Do nothing if there's no video.
    if (!bgEl) return;

    // Sources passed from WP into the data attributes.
    const desktopSrc = bgEl.dataset.desktopSrc;
    const mobileSrc = bgEl.dataset.mobileSrc || desktopSrc;

    // Set the src attribute to whichever data attribute is appropriate.
    bgEl.src = window.innerWidth < 700 ? mobileSrc : desktopSrc;
  };

  // Remove our setInterval() if this controller disconnects.
  disconnect = () => {
    this.stopTimer();
  };

  // Create a setInterval() to cycle on mobile every 2s.
  initTimer = () => {
    // Do nothing if there are no timestamps.
    if (this.timestampEls.length === 0) return;
    // Otherwise, call mobileTick() every 2s.
    this.timer = setInterval(() => this.mobileTick(), 3000);
  };

  // Clear the mobile setInterval() timer.
  stopTimer = () => (this.timer ? clearInterval(this.timer) : null);

  // This fires with the 2s setInterval (this.timer).
  mobileTick = () => {
    const currIdx = this.timestampEls.indexOf(
      this.timestampEls.filter(el => el.classList.contains("mobile-visible"))[0]
    );
    if (currIdx >= 0)
      this.timestampEls[currIdx].classList.remove("mobile-visible");
    const el = this.timestampEls[(currIdx + 1) % this.timestampEls.length];
    if (el) el.classList.add("mobile-visible");
  };

  // This fires when the time on the video updates.
  videoTick = e => {
    // Stop tracking time if no timestamps.
    if (this.timestampEls.length === 0) {
      // Grab the <video> DOM element.
      const vidEl = this.element.querySelector("video");
      // Replace the 'data-action' attribute from this.tick to nothing.
      vidEl.dataset.action = vidEl.dataset.action.replace(
        "timeupdate->viewport-hero#tick",
        ""
      );
      // Return without doing the timestamp calculations.
      return;
    }

    // Get the video time (in seconds).
    const videoTime = e.target.currentTime;

    // Add the '.active' class to the last past timestamp.
    const pastArray = this.timestampEls.filter(
      t => videoTime >= t.dataset.seconds
    );
    const lastPast = pastArray[pastArray.length - 1];

    // Only update DOM if the active timestamp has changed.
    if (lastPast && !lastPast.classList.contains("active")) {
      this.timestampEls.map(t => t.classList.remove("active"));
      lastPast.classList.add("active");
    }
  };
}
