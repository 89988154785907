export function serializeFormData(form) {
  let serialized = {};
  filteredFormInputs(form).forEach(field => {
    if (field.type === "checkbox") {
      if (!serialized[field.name]) serialized[field.name] = [];
      if (field.checked) serialized[field.name].push(field.value);
    } else {
      serialized[field.name] = field.value;
    }
  });
  return serialized;
}

// Builds query string from form data with no special processing
export function formParams(form) {
  return filteredFormInputs(form)
    .map(
      input =>
        `${encodeURIComponent(input.name)}=${encodeURIComponent(input.value)}`
    )
    .join("&");
}

function filteredFormInputs(form) {
  return Array.from(form.elements).filter(
    field =>
      field.name &&
      !field.disabled &&
      field.type !== "file" &&
      field.type !== "reset" &&
      field.type !== "submit" &&
      field.type !== "button" &&
      (field.checked || ["radio", "checkbox"].indexOf(field.type) == -1)
  );
}

export function serializedQueryParams() {
  let query = window.location.search.substring(1);
  if (query === "") return {};
  let vars = query.split("&");
  let obj = {};
  vars.forEach(part => {
    let pair = part.split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  });
  return obj;
}

export function objectToQuery(params) {
  return Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&");
}
